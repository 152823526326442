<template>
  <div v-if='!preload'>
    <div class="bg-azul d-block d-sm-block d-md-none " id="footer-mobile">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <h4 class="text-white footer-title">¿Necesitas ayuda?</h4>
          </div>
        </div>

        <div class="row text-muted pt-3 pb-4">
          <div class="col-12">
            <div class="">
              <div class="row">
                <a class="btn btn-footer " href="tel:+34952864645">
                  <div class="d-flex justify-content-center">
                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="icon" d="M3.62 8.03393C5.06 10.8691 7.38 13.1933 10.21 14.6359L12.41 12.4319C12.69 12.1514 13.08 12.0713 13.43 12.1815C14.55 12.5521 15.75 12.7525 17 12.7525C17.2652 12.7525 17.5196 12.8581 17.7071 13.0459C17.8946 13.2338 18 13.4886 18 13.7543V17.2607C18 17.5264 17.8946 17.7812 17.7071 17.9691C17.5196 18.157 17.2652 18.2625 17 18.2625C12.4913 18.2625 8.1673 16.4682 4.97918 13.2743C1.79107 10.0803 0 5.74845 0 1.23156C0 0.965858 0.105357 0.711041 0.292893 0.523163C0.48043 0.335285 0.734784 0.229736 1 0.229736H4.5C4.76522 0.229736 5.01957 0.335285 5.20711 0.523163C5.39464 0.711041 5.5 0.965858 5.5 1.23156C5.5 2.48383 5.7 3.68602 6.07 4.80806C6.18 5.1587 6.1 5.54941 5.82 5.82992L3.62 8.03393Z" fill="white" />
                    </svg>
                    <span class="pl-2">952 864 645</span>
                  </div>
                </a>
                <a class="btn btn-footer " href="mailto:info@ibancar.com">
                  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="icon" d="M2.2 0.404785H19.8C20.3835 0.404785 20.9431 0.64227 21.3556 1.065C21.7682 1.48772 22 2.06106 22 2.65888V16.1835C22 16.7813 21.7682 17.3546 21.3556 17.7774C20.9431 18.2001 20.3835 18.4376 19.8 18.4376H2.2C0.979 18.4376 0 17.4232 0 16.1835V2.65888C0 1.40786 0.979 0.404785 2.2 0.404785ZM11 8.29413L19.8 2.65888H2.2L11 8.29413ZM2.2 16.1835H19.8V5.32999L11 10.954L2.2 5.32999V16.1835Z" fill="white" />
                  </svg>
                  <span class="pl-2">info@ibancar.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-around align-items-center text-muted p-2">
          <div class="col-6">
            <p><router-link class="text-white" to="/politica-privacidad" target="_blank">Política de privacidad</router-link></p>
            <p><router-link class="text-white" to="/politica-cookies" target="_blank">Política de cookies</router-link></p>
            <p><router-link class="text-white" to="/aviso-legal" target="_blank">Aviso legal</router-link></p>
          </div>
          <div class="col-6 text-center">
            <img width="134" height="67" src="@/assets/img/rgpd_small_webp.webp" alt="Ibancar" />
          </div>
          <div class="col-12">
            <div class="card fondo-footer-text">
                  <div class="card-body t12">
                      <p class="card-text">Oferta de financiación sujeta a aprobación de Ibancar World SL en España. Préstamos al consumo entre 300€ y 6000€ y duración entre 12 y 36 meses. El TAE podrá variar en función de la cantidad a prestar. Variación de la TAE 16.06% (min.) y 16.07% (máx.) y TIN 15% (mín.) . Importe calculado para financiaciones realizadas el día 1 del mes, y primer vencimiento el 1 del mes siguiente; en caso de que las fechas difieran, el importe total puede sufrir pequeñas variaciones. Sistema de amortización francés.</p>
                      <p class="card-text">*Para un importe solicitado de 1000€ en 12 cuotas mensuales de 90.26€ (amortización francesa). TAE: 16.07%, TIN: 15.00%. Importe de los intereses: 83€. Coste total del préstamo: 1083€.</p>
                      <p class="card-text">*Para un importe solicitado de 6000€ en 36 cuotas mensuales de 207.99€ (amortización francesa). TAE: 16.07%, TIN: 15.00%. Importe de los intereses: 1488€. Coste total del préstamo: 7488€.</p>
                      <p class="card-text">*Coste de servicios no incluidos</p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray d-block d-sm-block d-md-none">
      <div class="container">
        <div class="row align-items-center justify-content-center pt-3">
          <p class="copyright">Copyright © Ibancar {{ new Date().getFullYear() }}</p>
          <img alt="metricool_pixel" rel="preconnect" src="https://tracker.metricool.com/c3po.jpg?hash=f9cab7082580b380cabbc39b57a755" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
export default {
  name: "FooterFormulario",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  store,
  computed: mapState({
    preload: (state) => state.preload,
  }),
};
</script>

<style scoped lang="scss">
.bg-gray {
  color: #fff;
  font-size: 10px;
  background: linear-gradient(0deg, #18333d, #18333d), #003159;
}

.btn-footer {
  height: 45px;
  font-weight: bolder;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover {
  height: 45px;
  font-weight: bolder;
  color: #004680;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover .icon {
  fill: #004680;
}

h4 {
  font-family: "Montserrat-Bold";
}
.fondo-footer-text{
    background-color: #003159;
    color:white;
    border: none;
}
// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.footer-title{
  font-size: 1rem
}
@include media-breakpoint-only(xs) {
  .bg-azul {
    z-index: -1;
    background-color: #003159;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .copyright {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-only(sm) {
  .bg-azul {
    z-index: -1;
    background-color: #003159;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .copyright {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-only(md) {
  .bg-azul {
    z-index: -1;
    background-color: #003159;
    padding-top: 3rem;
    padding-left: 5.5rem;
    padding-right: 4rem;
    padding-bottom: 3rem;
  }
}

</style>
